import React from "react";
import { Container } from 'components/library';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CtaBlock } from "components/common/cta-block";

const PublicNoticePage = () => {
    const { t } = useTranslation()

    return (
        <main className='-mt-28 sm:-mt-36'>
            <section className='relative text-white flex flex-col gap-8 pb-12 sm:pb-16 pt-40 sm:pt-60 items-center text-center px-8 bg-hero-bg-flat-mobile sm:bg-hero-bg-flat bg-no-repeat bg-cover'>
                <h1 className='text-4xl sm:text-5xl md:text-6xl max-w-4xl'>
                    {t('footer.navigation-items.4.subitems.7')}
                </h1>
            </section>

            <Container className='flex flex-col gap-20 py-24'>
                <section className='flex flex-col gap-10 max-w-[700px] mx-auto'>
                        <p className="text-right">2023 年8 月17 日</p>
                        <div className="text-right">
                            <p>Polymerize 合同会社</p>
                            <p>社長　クナル・サンディープ</p>
                        </div>
                        
                        <p className="underline text-center">事務所移転のお知らせ</p>

                        <p>お客様、ビジネスパートナー各位</p>
                        <p>拝啓　時下ますますご清栄のこととお慶び申し上げます。さてこの度、2023 年8 月17 日より
                            以下の住所に弊社事務所を移転し、営業を開始いたしましたので、ここにお知らせいたします。
                            改めて日頃のご愛顧に御礼申し上げると共に、今後とも変わらぬご支援を賜りますようお願い
                            申し上げます。
                        </p>
                        <p className="text-right">敬具</p>
                        <div className="text-center">
                            <p>＜新住所＞</p>
                            <p>〒105-6415 東京都港区虎ノ門1 丁目17 番1 号</p>
                            <p>虎ノ門ヒルズビジネスタワー15F</p>
                        </div>

                        <p className="text-right">以上</p>
                </section>
            </Container>
            <CtaBlock />
        </main>
    )
};
export default PublicNoticePage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 